import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { FastField, Field } from '~/components/common/Formik';
import themes, { styled } from '~/themes';
import Button from '~/components/common/Button';
import NotConnectedIcon from '~/assets/images/icons/not-connected.svg';
import { IntegrationStatus, IStore, StorePlatform } from '~/models/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { formatPhoneNumber } from '~/utils/formatter';
import ConnectedIcon from '~/assets/images/icons/connected.svg';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import { UpdateStoreSchema } from '~/utils/schema/stores';
import { useCheckConnectionInAdmin } from '~/services/api/stores';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { CanFleetPluginURL } from '~/constants/stores';
import { getPlatformIcon } from '~/utils/settings';
import { useAuth } from '~/providers/AuthProvider';
import {
  StyledConnectedIcon,
  StyledPlatformIcon,
  StyledStatusText,
} from '../../style';

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.color.black,
  fontWeight: 600,
}));

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9,
  background: 'rgba(256, 256, 256, 0.7)',
}));

type GeneralProps = {
  storeData: IStore;
  isFetching?: boolean;
};

export const General: React.FC<GeneralProps> = ({ storeData, isFetching }) => {
  const { countries, isFetchingCountry } = useAuth();

  const { mutate: updateStoreMutation, isLoading: isUpdateLoading } =
    useUpdateConnectionMutation();

  const {
    mutate: checkConnectionMutation,
    isLoading: isCheckConnectionLoading,
  } = useCheckConnectionInAdmin({
    onSuccess: (resp) => {
      if (storeData.connected !== resp.connected) {
        queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      }
    },
  });

  const location = useLocation();
  const search = fromQueryString(location.search);
  const platform = search.platform as StorePlatform;
  const renderWooStoreInfo = (values, setFieldValue) => (
    <Grid container spacing={2} columnSpacing={[2, 8]}>
      <Grid item xs={12} md={12}>
        <StyledTitle variant='h5'>Store Location</StyledTitle>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.TextInput legend='Store Name' name='display_name' />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.TextInput legend='Phone Number' name='phone' />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.TextInput legend='Address Line 1' name='address_1' />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.TextInput legend='Address Line 2' name='address_2' />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.Select
          legend='Country'
          name='country'
          options={countries}
          disabled={isFetchingCountry}
          onChange={() => {
            setFieldValue('state', '');
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.Select
          legend='Province / State'
          name='state'
          options={
            countries.find(({ value }) => value === values.country)?.states ||
            []
          }
          disabled={isFetchingCountry}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastField.TextInput legend='City' name='city' />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastField.TextInput legend='Postal Code' name='postcode' />
      </Grid>
    </Grid>
  );

  const convertAddress = (values: any) =>
    [values?.address_1, values?.city, values?.state, values?.postcode]
      .filter((x) => !!x)
      .join(', ');

  const renderStoreLocation = () => (
    <Stack spacing={2} sx={{ my: 2 }}>
      <Typography variant='h5' color={themes.color.black}>
        {storeData?.number_of_hubs}
      </Typography>

      {storeData?.hubs?.map((store) => (
        <Stack
          component='li'
          key={store.id}
          spacing={1}
          p={1.5}
          mb={1}
          sx={{
            background:
              store?.status === 'failed'
                ? themes.bg.lightPink
                : themes.bg.lightPurple,
            borderRadius: '5px',
          }}
        >
          <Typography
            variant='h5'
            color={themes.color.black}
            data-testid='breadstack-wh-name'
          >
            {store.name}
          </Typography>
          <Stack
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            gap='16px'
          >
            <span>Address: {convertAddress(store)}</span>
            {store.status === 'failed' && (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  window.open(
                    `${storeData.breadstack_url}/${store?.metadata?.bs_warehouse_id}`,
                    '_blank',
                  )
                }
              >
                Update in Breadstack
              </Typography>
            )}
          </Stack>

          <span>
            Phone number:{' '}
            <a href={`tel:${store.phone || '-'}`}>
              {store.phone ? formatPhoneNumber(store.phone) : ''}
            </a>
          </span>
        </Stack>
      ))}
      {platform === StorePlatform.Breadstack && (
        <Button
          noRounder
          buttonType='default'
          sx={{ width: 'fit-content' }}
          onClick={() => window.open(storeData.breadstack_url, '_blank')}
        >
          Manage in Breadstack
        </Button>
      )}
    </Stack>
  );

  const pluginUrl =
    platform === StorePlatform.Breadstack
      ? CanFleetPluginURL.Breadstack
      : CanFleetPluginURL.Woo;

  return (
    <Formik
      initialValues={{
        display_name: '',
        phone: '',
        address_1: '',
        address_2: '',
        country: '',
        city: '',
        state: '',
        postcode: '',
        id: '',
        ...storeData,
        status: storeData?.status === IntegrationStatus.Active,
      }}
      enableReinitialize
      validationSchema={
        platform === StorePlatform.Breadstack ? null : UpdateStoreSchema
      }
      onSubmit={(values) => {
        let params;
        if (platform === StorePlatform.Breadstack) {
          params = {
            id: values.id,
            status: values.status
              ? IntegrationStatus.Active
              : IntegrationStatus.Deactivated,
          };
        } else {
          params = {
            ...values,
            status: values.status
              ? IntegrationStatus.Active
              : IntegrationStatus.Deactivated,
          };
        }

        updateStoreMutation(params);
      }}
    >
      {({ values, isValid, dirty, setFieldValue }) => (
        <Form>
          {isFetching && (
            <StyledLoading>
              <CircularProgress size={30} />
            </StyledLoading>
          )}
          <StyledTitle variant='h5'>eCommerce Integration</StyledTitle>
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='space-between'
            sx={{
              p: 1.5,
              mt: 2,
              mb: 1,
              bgcolor: themes.bg.lightPurple,
              borderRadius: '5px',
              [themes.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{
                [themes.breakpoints.down('sm')]: {
                  pb: 1,
                },
              }}
            >
              <StyledPlatformIcon
                src={getPlatformIcon(platform)}
                alt='platform-icon'
              />
              <a
                href={storeData.store_url}
                data-testid='store-url'
                target='_blank'
                rel='noreferrer'
              >
                {storeData.store_url}
              </a>
            </Stack>
            <Field.SwitchButton noText name='status' />
          </Stack>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <Button
              buttonType='default'
              noRounder
              sx={{ mr: 1 }}
              loading={isCheckConnectionLoading}
              onClick={() =>
                checkConnectionMutation({ integration_id: storeData.id })
              }
            >
              Check Connection
            </Button>
            <StyledConnectedIcon
              alt='connection-icon'
              src={storeData.connected ? ConnectedIcon : NotConnectedIcon}
            />
            <StyledStatusText enabled={storeData.connected}>
              {storeData.connected ? 'Connected' : 'Not connected'}
            </StyledStatusText>
          </Stack>
          {platform === StorePlatform.Shopify ? (
            <Typography sx={{ my: 2 }}>
              Please make sure you have installed Breadstack Delivery on{' '}
              <a href={pluginUrl} rel='noreferrer'>
                Shopify
              </a>{' '}
              and enabled the shipping method for your site.
            </Typography>
          ) : (
            <Typography sx={{ my: 2 }}>
              Please make sure you have installed the{' '}
              <a href={pluginUrl} rel='noreferrer'>
                Breadstack Delivery Plugin
              </a>{' '}
              and enabled the shipping method for your site.
            </Typography>
          )}
          <Divider sx={{ my: 2 }} />

          {platform === StorePlatform.Woo || platform === StorePlatform.Shopify
            ? renderWooStoreInfo(values, setFieldValue)
            : ''}

          {platform === StorePlatform.Breadstack ? renderStoreLocation() : ''}
          <Button
            className='save-change-btn'
            type='submit'
            loading={isUpdateLoading}
            fullWidth
            disabled={!isValid || !dirty}
          >
            Save Changes
          </Button>
        </Form>
      )}
    </Formik>
  );
};
