import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import DingAudio from '~/assets/audio/ding.mp3';
import { useCreateNotiSubscribeMutation } from '~/services/api/shared';
import { set, LOCAL_STORAGE_KEYS } from '~/utils/localStorage';
import { NotificationsService } from './notificationServices';

export enum NotiTaskType {
  NewTask = 'new_task',
  Completed = 'complete_task',
  NewDriver = 'driver_first_time_login',
}
declare module 'notistack' {
  interface VariantOverrides {
    task: {
      taskName: string;
      type: NotiTaskType;
    };
  }
}

export const Notifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: createNotiSubscribeMutation } =
    useCreateNotiSubscribeMutation({
      onSuccess: (resp: { registration_id?: string }) => {
        if (resp?.registration_id) {
          set(LOCAL_STORAGE_KEYS.FCM_REGISTRATION_ID, resp?.registration_id);
        }
      },
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShowTaskNotification = ({ taskName, message, type }) => {
    const dingAudio = new Audio(DingAudio);
    dingAudio.addEventListener('canplaythrough', () => {
      dingAudio.play().catch(() => {
        // @NOTE: read https://developer.chrome.com/blog/autoplay/
      });
    });

    enqueueSnackbar(message, {
      variant: 'task',
      taskName,
      type,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 3 * 1000,
    });
  };

  const handleLoadSw = async () => {
    const notificationsService = new NotificationsService();
    await notificationsService.registerWorker();
    const permission = await Notification.requestPermission();
    if (permission === 'denied') {
      // eslint-disable-next-line no-console
      console.error(`[onSubscribe] `, { permission });
    }
    const token = await notificationsService.getWebToken();
    createNotiSubscribeMutation({
      active: true,
      type: 'web',
      registration_id: token,
    });

    notificationsService.onNotifications((notification) => {
      const data: { message: string; task_name: string; type: NotiTaskType } =
        notification?.data || {};
      // eslint-disable-next-line no-console
      console.log(notification);
      handleShowTaskNotification({
        taskName: data.task_name,
        message: data.message || '',
        type: data.type,
      });
    });
  };
  useEffect(() => {
    handleLoadSw();
  }, []);

  return null;
};
