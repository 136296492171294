import { IHub } from './shared';

export enum StorePlatform {
  Woo = 'woocommerce',
  Breadstack = 'breadstack',
  Shopify = 'shopify',
}

export enum IntegrationType {
  Woo = 'woocommerce',
  Breadstack = 'breadstack',
  Shopify = 'shopify',
}

export interface IStore {
  type?: string;
  name?: string;
  org_id?: string;
  api_key?: string;
  created_at?: Date;
  updated_at?: Date;
  area_type?: DeliveryZoneSets;
  linked_hub?: string;
  delivery?: Delivery;
  linked_warehouses?: any[];
  re_generate_api_key?: boolean;
  id?: string;
  hub_name?: string;
  is_enable?: boolean;
  enable_delivery?: boolean;
  display_name?: string;
  integration_type?: IntegrationType;
  username?: string;
  store_url?: string;
  status?: IntegrationStatus;
  skip_pickup?: boolean;
  connected?: boolean;
  breadstack_url?: string;
  hubs?: IHub[];
  limit_all_slot?: number;
  limit_type: OrderLimitType;
  store_require_pickup_proofs?: boolean;
  store_require_delivery_capture?: boolean;
  store_require_delivery_signature?: boolean;
  number_of_hubs?: string;
}

export enum DeliveryZoneSets {
  DrawnZones = 'polygon',
  DistanceRadius = 'radius',
  PostalCode = 'postal_code',
}

export interface Delivery {
  delivery_hours?: DeliveryHours;
  delivery_window?: number;
  next_available_time?: number;
  special_hours?: SpecialHour[];
  delivery_hour_type?: DeliveryHourType;
}

export interface DeliveryHours {
  monday?: DateElement;
  tuesday?: DateElement;
  wednesday?: DateElement;
  thursday?: DateElement;
  friday?: DateElement;
  saturday?: DateElement;
  sunday?: DateElement;
}

export interface DateElement {
  from?: string;
  to?: string;
  limit?: number;
  time_slot: {
    from?: string;
    to?: string;
    limit?: number;
  }[];
}

export enum OrderLimitType {
  LimitAllSlot = 'all',
  LimitIndividualSlot = 'individual',
}

export enum IndividualTimeSlotsType {
  LimitAllSlot = 'limit_all_slot',
  LimitIndividualSlot = 'limit_individual_slot',
}

export interface SpecialHour {
  start_date?: Date;
  end_date?: Date;
  delivery_hours?: { [key: string]: DateElement };
  delivery_window?: number;
  next_available_time?: number;
  name?: string;
}

export enum IntegrationStatus {
  Active = 'active',
  Deactivated = 'deactivated',
}

export interface Zone {
  integration_id?: string;
  name?: string;
  enable?: boolean;
  delivery_fee?: number;
  delivery_fee_type?: string;
  delivery_minimum?: number;
  area_type?: string;
  long_lat?: Array<number[]>;
  org_id?: string;
  created_at?: Date;
  updated_at?: Date;
  id?: string;
  max_delivery_distance_type?: string;
  max_delivery_distance?: string;
}

export const enum CreateStoreStatus {
  Deactivated = 'deactivated',
  isChecking = '',
  Active = 'active',
}
export const enum ConnectionStoreStatus {
  Connected = 'connected',
  Checking = 'checking',
  NotConnected = 'not-connected',
}

export interface ICreateStore {
  integration_type?: IntegrationType;
  display_name?: string;
  address_1?: string;
  address_2?: string;
  country?: string;
  country_code?: string;
  state?: string;
  city?: string;
  postcode?: string;
  phone?: string;
  store_url?: string;
  store_id?: string;
  email?: string;
  password?: string;
  store?: string;
  status?: CreateStoreStatus;
}

export interface ICheckConnectionResponse {
  connected: boolean;
}

export interface ICheckConnectionParams {
  integration_type: IntegrationType;
  store_url: string;
  store_id?: string;
}

export interface IBSStoreParams {
  email: string;
  password: string;
}

export interface IBSStore {
  store_url: string;
  store_name: string;
  store_id: string;
}

export enum DeliveryHourType {
  Different = 'different',
  Same = 'same',
}

export const DeliveryHourOptions = [
  {
    value: DeliveryHourType.Different,
    label: 'Set different hours for each operation date',
  },
  {
    value: DeliveryHourType.Same,
    label: 'Set the same hour for all operation dates',
  },
];
