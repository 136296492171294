import React, { useState, useRef, useEffect } from 'react';
import ReactDatetimeClass, { DatetimepickerProps } from 'react-datetime';
import clsx from 'clsx';
import 'react-datetime/css/react-datetime.css';
import { FormLabel, IconButton } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { omit } from 'lodash';
import { TextInput } from '~/components/common/TextInput';
import DayPickerIcon from '~/assets/images/icons/date-picker.svg';
import { IOptionItem } from '~/models/common';
import moment from 'moment';
import Button from '../Button';
import style from './style';

const ReactDatetime: any = ReactDatetimeClass;
const Datetime = ReactDatetime.default ?? ReactDatetimeClass;

export interface IDateSelectorProps extends DatetimepickerProps {
  loading?: boolean;
  initialValue?: string;
  invalidDays?: string[];
  disabled?: boolean;
  className?: string;
  legend?: string;
  placeholder?: string;
  dateFormat?: boolean;
  timeFormat?: boolean;
  showIcon?: boolean;
  dirty?: boolean;
  timeList?: IOptionItem[];
  timeGap?: number;
  cancelOnClear?: boolean;
  formValue?: string;
  leftItem?: React.ReactNode;
  error?: boolean;
  disablePastDate?: boolean;
  helperText?: string;
  onCancel?: () => void;
  onApply?: (val: any) => void;
  onClear?: () => void;
  id?: string;
}

const DateSelector: React.FC<IDateSelectorProps & WithStyles<typeof style>> = ({
  disabled,
  className = '',
  placeholder,
  classes,
  legend,
  invalidDays,
  showIcon = false,
  dirty = false,
  formValue,
  error,
  helperText,
  dateFormat,
  timeFormat = false,
  disablePastDate = false,
  onCancel,
  onApply,
  id,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(formValue);
  const timePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onOutsideClick = (e: any) => {
      if (
        timePickerRef &&
        timePickerRef.current &&
        !timePickerRef.current.contains(e.target) &&
        typeof e.target.className?.includes === 'function' &&
        !e.target.className.includes('MuiDialog') &&
        e.target.className &&
        !e.target.className.includes('MuiButton') &&
        e.target.className &&
        !e.target.className.includes('MuiBackdrop')
      ) {
        setOpen(false);
      }
    };

    if (!disabled) window.addEventListener('mousedown', onOutsideClick);

    return () => {
      window.removeEventListener('mousedown', onOutsideClick);
    };
  }, []);

  const handleChange = (val: any) => {
    setValue(val);
  };

  const yesterday = moment().subtract(1, 'day');

  const disableDate = (current) => {
    if (
      invalidDays?.length &&
      invalidDays.includes(moment(current).format('MM/DD/YYYY'))
    ) {
      return false;
    }

    if (disablePastDate) {
      return moment(current).isAfter(yesterday);
    }
    return true;
  };

  const renderView = (_: any, renderDefault: any) => (
    <div className={clsx(classes.wrapper)}>
      {renderDefault()}
      <div className={classes.footer}>
        <Button
          buttonType='default'
          onClick={() => {
            setOpen(!open);
          }}
        >
          Cancel
        </Button>
        {!!onApply && (
          <Button
            buttonType='default'
            onClick={() => {
              onApply(moment(value).format('MM/DD/YYYY'));
              setOpen(false);
            }}
          >
            Apply
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      {legend && (
        <FormLabel className={classes.formLabel} component='legend'>
          {legend}
        </FormLabel>
      )}
      <div ref={timePickerRef} className={classes.container}>
        <Datetime
          className={clsx(classes.dateTimeContainer, {
            [className]: !!className,
          })}
          value={value}
          open={open}
          onOpen={() => {
            setOpen(!open);
          }}
          onClose={() => {
            setOpen(!open);
            if (onCancel) onCancel();
          }}
          isValidDate={disableDate}
          onChange={handleChange}
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
          renderInput={({ onClick, className: _, ...xProps }) =>
            showIcon ? (
              <IconButton
                {...omit(xProps, ['type'])}
                className={clsx(classes.icon, {
                  [classes.dirtyIcon]: dirty,
                  [classes.activeIcon]: open,
                })}
                size='large'
              >
                <img src={DayPickerIcon} alt='day-picker' />
              </IconButton>
            ) : (
              <TextInput
                id={id || ''}
                {...xProps}
                placeholder={placeholder}
                // eslint-disable-next-line no-nested-ternary
                value={formValue}
                error={error}
                helperText={helperText}
                inputProps={{
                  readOnly: true,
                }}
                suffix={
                  !open ? (
                    <KeyboardArrowDown
                      color={disabled ? 'disabled' : 'inherit'}
                      style={{ paddingRight: 9 }}
                    />
                  ) : (
                    <KeyboardArrowUp
                      color={disabled ? 'disabled' : 'inherit'}
                      style={{ paddingRight: 9 }}
                    />
                  )
                }
              />
            )
          }
        />
      </div>
    </>
  );
};

export default withStyles(style)(DateSelector);
