import { Grid } from '@mui/material';
import { Box, Container } from '@mui/system';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import Donut from '~/components/common/Chart/Donut';
import { IDonutItem } from '~/components/common/Chart/Donut/Donut';
import Rating from '~/components/common/Chart/Rating';
import { IRatingItem } from '~/components/common/Chart/Rating/Rating';
import Summary from '~/components/common/Chart/Summary';
import { ISummaryItem } from '~/components/common/Chart/Summary/Summary';
import Tasks from '~/components/common/Chart/Tasks';
import { ITaskItem } from '~/components/common/Chart/Tasks/Tasks';
import { Select } from '~/components/common/Select';
import { TIMEZONE_SETTING } from '~/constants/adminOptions';
import { DEFAULT_TIMEZONE } from '~/constants/common';
import { useAuth } from '~/providers/AuthProvider';
import { useGetDashboardQuery } from '~/services/api/dashboard';
import themes from '~/themes';
import { getHourAndMinLabel, getHourMinSecLabel } from '~/utils/common';
import { IOptionItem } from '~/models/common';
import { HitMapProvider } from './context/HitMapContext';
import { DropOffHitMap } from './components/DropOffHitMap';
import { DriverTable } from './components/DriverTable';
import { NewDropOffHitMap } from './components/DropOffHitMap/NewDropOffHitMap';

interface IDashboard {}

const driverUtilColors = ['#E5E5E5', '#34C336'];

const taskAssignColors = ['#FF6C00', '#34C337'];

const DateOption: IOptionItem[] = [
  { value: 7, label: 'Last 7 days' },
  { value: 14, label: 'Last 14 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 90, label: 'Last 90 days' },
];

const randomIntFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const Dashboard: React.FC<IDashboard> = () => {
  // const length = 30;
  const isNewHeatMap = useFeatureIsOn('cf-show-new-heat-map');
  const [selectedDate, setSelectedDate] = useState<number>(
    DateOption[1].value as number,
  );
  const date_gte = moment().subtract(selectedDate, 'day').format('YYYY-MM-DD');
  const { account } = useAuth();

  const timezone = useMemo(() => {
    if (account.timezone) {
      if (account.timezone === TIMEZONE_SETTING.AUTO_DETECT) {
        let tz = momentTz.tz.guess();
        if (tz === 'Asia/Saigon') {
          tz = 'Asia/Ho_Chi_Minh';
        }
        return tz;
      }
      return account.timezone;
    }
    return DEFAULT_TIMEZONE;
  }, [account.org]);

  const { data, isFetching: isLoadingDashboard } = useGetDashboardQuery({
    params: {
      length: selectedDate,
      date_gte,
      timezone,
    },
    enabled: !!timezone,
  });

  const isLoading = !timezone || isLoadingDashboard;

  const summaryData: ISummaryItem[] = [
    { title: 'Complete Task', count: data?.completed_tasks || 0 },
    { title: 'Unique Customer', count: data?.unique_recipients || 0 },
    { title: 'Late Task', count: data?.late_tasks || 0 },
    { title: 'Cancelled Task', count: data?.cancelled_tasks || 0 },
    { title: 'Returned Task', count: data?.returned_tasks || 0 },
  ];
  let isTaskEmpty = true;
  const emptyTaskList = Array(selectedDate)
    .fill(0)
    .reduce(
      (rs, item, index) =>
        rs.concat([
          {
            date: moment()
              .subtract(selectedDate - index, 'day')
              .format('MMM DD'),
            tooltipDate: moment()
              .subtract(selectedDate - index, 'day')
              .format('YYYY-MM-DD'),
            value: randomIntFromInterval(15, 25),
            type: 'On time tasks',
          },
          {
            date: moment()
              .subtract(selectedDate - index, 'day')
              .format('MMM DD'),
            tooltipDate: moment()
              .subtract(selectedDate - index, 'day')
              .format('YYYY-MM-DD'),
            value: randomIntFromInterval(15, 25),
            type: 'Late tasks',
          },
        ]),
      [],
    );
  // TODO: data?.on_time_and_late_tasks_by_day?.data || []
  const taskData: ITaskItem[] = (
    data?.on_time_and_late_tasks_by_day?.data || []
  ).reduce((rs: any[], item) => {
    if (item[1] !== 0 || item[2] !== 0) {
      isTaskEmpty = false;
    }
    return rs.concat([
      {
        date: moment(item[0]).format('MMM DD'),
        tooltipDate: moment(item[0]).format('MMMM DD, YYYY'),
        value: item[1],
        type: 'On time tasks',
      },
      {
        date: moment(item[0]).format('MMM DD'),
        tooltipDate: moment(item[0]).format('MMMM DD, YYYY'),
        value: item[2],
        type: 'Late tasks',
      },
    ]);
  }, []);

  const driverUtilData: IDonutItem[] = [
    {
      value: data?.time_driver_idle_per_day || 0,
      quantity: data?.time_driver_idle_per_day || 0,
      type: 'Idle',
      convertedValue: getHourAndMinLabel(data?.time_driver_idle_per_day || 0),
    },
    {
      value: data?.time_driver_spent_on_tasks_per_day || 0,
      quantity: data?.time_driver_spent_on_tasks_per_day || 0,
      type: 'In Transit',
      convertedValue: getHourAndMinLabel(
        data?.time_driver_spent_on_tasks_per_day || 0,
      ),
    },
  ];

  const taskAssignTitle = getHourMinSecLabel(data?.avg_time_to_assignment || 0);

  const taskAssignDescription = 'Average Assign Duration';

  const taskAssignData: IDonutItem[] = [
    {
      value: data?.tasks_with_reassignment || 0,
      quantity: data?.tasks_with_reassignment || 0,
      type: 'Reassigned Tasks',
      convertedValue: `${data?.tasks_with_reassignment || 0}`,
      externalLink: `/tasks?tab=t_completed&reassigned_status=reassigned&date_gte=${date_gte}&length=${selectedDate}&timezone=${timezone}`,
    },
    {
      value: data?.tasks_without_reassignment || 0,
      quantity: data?.tasks_without_reassignment || 0,
      type: 'No Reassignment',
      convertedValue: `${data?.tasks_without_reassignment || 0}`,
      externalLink: `/tasks?tab=t_completed&reassigned_status=no_reassignment&date_gte=${date_gte}&length=${selectedDate}&timezone=${timezone}`,
    },
  ];

  const averageRating = data?.avg_rating || 0;

  const rating: IRatingItem[] = (data?.low_reviews || []).map((item) => ({
    id: item.id,
    feedback: item.feed_back,
    code: item.name,
    rating: item.rating,
    date: moment(item.updated_at).format('MMM DD'),
    searchQuery: !item.integration_type ? item.number : item.name,
  }));

  const storeLocations = useMemo(
    () =>
      data?.store_locations?.map(({ lat, lng }) => ({
        lat,
        lng,
      })) || [],
    [data],
  );

  return (
    <Box
      id='insight-container'
      className='customized-scrollbar'
      sx={{
        backgroundColor: themes.bg.lightPurple,
        width: '100%',
        height: '100%',
        overflowX: 'auto',
      }}
    >
      <Container maxWidth='lg'>
        <Box
          color={themes.color.violet900}
          fontSize={22}
          fontWeight='bold'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          pt={3}
          pb={2}
          width='100%'
        >
          <span>Insight</span>
          <Select
            formControlProps={{
              sx: {
                'maxWidth': 180,
                '&>div': {
                  'background': 'white',
                  '&>div': {
                    color: `${themes.color.violet900} !important`,
                  },
                },
              },
            }}
            name='selectedDate'
            onChange={(e) => setSelectedDate(e?.target?.value as number)}
            options={DateOption}
            value={selectedDate}
          />
        </Box>
        <Grid container spacing={1.5} mb={3}>
          <Grid item xs={12}>
            <Summary items={summaryData} isLoading={false} />
          </Grid>
          <Grid item xs={12}>
            <Tasks
              isLoading={isLoading || isTaskEmpty}
              items={isLoading || isTaskEmpty ? emptyTaskList : taskData}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Donut
              title='Driver Utilization Rate'
              items={driverUtilData}
              colors={driverUtilColors}
              customHtml={() =>
                '<div><div>Utilization</div><div>Rate</div></div>'
              }
              isLoading={
                isLoading ||
                (driverUtilData[0]?.value ?? 0) +
                  (driverUtilData[1]?.value ?? 0) ===
                  0
              }
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Donut
              title='Assignment Efficiency'
              items={taskAssignData}
              colors={taskAssignColors}
              bigTitle={taskAssignTitle}
              bigDescription={taskAssignDescription}
              titleSx={{ fontWeight: '500' }}
              customHtml={() =>
                '<div><div>Assign</div><div>Efficiency</div></div>'
              }
              isLoading={
                isLoading ||
                (taskAssignData[0]?.value ?? 0) +
                  (taskAssignData[1]?.value ?? 0) ===
                  0
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Rating
              isLoading={isLoading || rating?.length === 0}
              averageRating={averageRating}
              items={rating}
            />
          </Grid>
          <Grid item xs={12}>
            <DriverTable data={data?.driver_table_info || []} />
          </Grid>
          <Grid item xs={12}>
            {isNewHeatMap ? (
              <HitMapProvider
                extraConfigs={{
                  mapTypeControl: true,
                  fullscreenControl: true,
                  maxZoom: 17,
                }}
              >
                <NewDropOffHitMap
                  data={data?.heat_map_data || []}
                  storeLocations={storeLocations}
                />
              </HitMapProvider>
            ) : (
              <HitMapProvider>
                <DropOffHitMap
                  data={data?.hit_map_data || null}
                  storeLocations={storeLocations}
                />
              </HitMapProvider>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
