import { CookieAttributes } from 'js-cookie';
import { COOKIES_CROSS_DOMAIN, URLS } from '~/config';
import { INewAccountParam, ICreateOrgParams } from '~/models/auth';

const NEW_PASSWORD_DATA = {
  WEB: {
    title: 'Reset Success!',
    subTitle: 'You will be redirected to the login page...',
    underline: 'If your browser does not refresh,',
  },
  MOBILE: {
    title: 'Reset Success!',
    subTitle: 'Your password has been reset successfully',
    underline: null,
  },
};

const MAPPING_FIREBASE_ERROR = {
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'Wrong password',
};

const DEFAULT_CREATE_NEW_ACCOUNT_PARAMS: INewAccountParam = {
  full_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  agreeTerms: false,
  captcha_token: '',
};

const DEFAULT_BUSINESS_INFO_PARAMS: ICreateOrgParams = {
  name: '',
  category: '',
};

const DEFAULT_UNIFIED_NAVIGATION = {
  section1: [
    // {
    //   id: 'breadstack',
    //   url: 'https://bs.app.breadstack.io',
    //   caption: null,
    //   icon: 'breadstack',
    // },
    // {
    //   id: 'service',
    //   url: 'https://cs.app.breadstack.io',
    //   caption: null,
    //   icon: 'ubp_service',
    // },
    // {
    //   id: 'storefront',
    //   url: 'https://bs.app.breadstack.io',
    //   caption: null,
    //   icon: 'ubp_marketing',
    // },
    {
      id: 'canfleet',
      url: 'https://cf.app.breadstack.io',
      caption: null,
      icon: 'canfleet',
    },
  ],
  section2: [
    {
      id: 'canfleet_live_tracking',
      url: '/',
      caption: 'Live Tracking',
      icon: 'live_tracking',
    },
    {
      id: 'canfleet_task_list',
      url: '/tasks',
      caption: 'Task List',
      icon: 'task_list',
    },
    {
      id: 'canfleet_address_book',
      url: '/address',
      caption: 'Address Book',
      icon: 'address_book',
    },
    {
      id: 'canfleet_insight',
      url: '/insight',
      caption: 'Insight',
      icon: 'insight',
    },
  ],
  section3: [
    // {
    //   id: 'profile_photo',
    //   url: '',
    //   caption: null,
    //   icon: null,
    //   popup_items: [
    //     {
    //       id: 'log_out',
    //       url: 'None',
    //       caption: 'Log out',
    //       icon: 'ubp_logout',
    //     },
    //   ],
    // },
  ],
  section4: [
    {
      id: 'canfleet_help_center',
      url: 'https://canfleetsupport.breadstack.com/portal/en/home',
      caption: 'Help Center',
      icon: 'help_center',
    },
    {
      id: 'canfleet_workspace',
      url: null,
      caption: 'Work Space',
      icon: 'workspace',
    },
    {
      id: 'canfleet_setting',
      url: '/setting',
      caption: 'Settings',
      icon: 'setting',
    },
  ],
};

export const CUSTOM_TOKEN_COOKIES_KEY = 'customToken';
export const RF_CUSTOM_TOKEN_COOKIES_KEY = 'refreshToken';
export const CookieOptions: CookieAttributes = {
  domain: COOKIES_CROSS_DOMAIN,
  sameSite: 'None',
  secure: true,
  path: '/',
  expires: 3,
};

export const NumberOfTaskOptions = [
  {
    label: 'Less than 100',
    value: 'Less than 100',
    apiValue: 'Less than 100',
  },
  {
    label: '100 - 2,000',
    value: '100 - 2000',
    apiValue: '100 - 2,000',
  },
  {
    label: 'More than 2,000',
    value: 'More than 2000',
    apiValue: 'More than 2,000',
  },
];

const urls = URLS ? JSON.parse(URLS) : {};

export const agreeTermsURL = urls.term;
export const helpCenterURL = urls.helpCenter;

export {
  NEW_PASSWORD_DATA,
  MAPPING_FIREBASE_ERROR,
  DEFAULT_CREATE_NEW_ACCOUNT_PARAMS,
  DEFAULT_BUSINESS_INFO_PARAMS,
  DEFAULT_UNIFIED_NAVIGATION,
};
