import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { AddSpecialHours } from '~/utils/schema/integration';
import { Field } from '~/components/common/Formik';
import {
  Divider,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import themes from '~/themes';
import moment from 'moment';
import { GAP_TIME_OPTIONS } from '~/constants/stores';
import {
  DeliveryHoursSelect,
  DeliveryHoursSelectItem,
  HourError,
} from './DeliveryHoursSelect';

interface DialogAddSpecialHoursProps {
  open: boolean;
  onClose: () => void;
  specialHours?: any;
  onSave: (val: any) => void;
  invalidDays: any;
}

export const DialogAddSpecialHours: React.FC<DialogAddSpecialHoursProps> = ({
  open,
  onClose,
  specialHours,
  invalidDays,
  onSave,
}) => {
  const isEdit = !!specialHours;
  const [dateError, setDateError] = useState('');
  const [errors, setErrors] = useState<Array<HourError>>([]);

  const onSubmit = (values) => {
    if (!values.start_date || !values.end_date) {
      return setDateError('Please enter Start Date or End Date');
    }

    values.start_date = moment(values.start_date).local().format('YYYY-MM-DD');
    values.end_date = moment(values.end_date).local().format('YYYY-MM-DD');
    onSave(values);
    return onClose();
  };

  const convertedSpecialHours = (deliveryHours) => {
    const days = Object.keys(deliveryHours);
    return days.map((d) => {
      const hours = deliveryHours[d];
      const is24Hour =
        hours.length === 1 &&
        hours[0].from === '00:00' &&
        hours[0].to === '24:00';
      const isClosed =
        hours.length === 1 && hours[0].from === '' && hours[0].to === '';
      return {
        weekdayValue: d,
        weekDayLabel: moment(d).local().format('dddd, MM/DD'),
        hours,
        is24Hour,
        isClosed,
      };
    });
  };
  const getDaysBetweenDates = (start, end) => {
    let now = moment(start);
    const endM = moment(end);
    const dates = [];

    while (now.isSameOrBefore(endM)) {
      dates.push(now.toDate());
      now = now.add(1, 'days');
    }
    return dates;
  };

  const handleApplyDate = (startDate, endDate, setFieldValue) => {
    if (!startDate || !endDate) return false;
    if (moment(startDate).unix() <= moment(endDate).unix()) {
      const days = getDaysBetweenDates(startDate, endDate);
      const deliveryHours = {};
      days.forEach((d) => {
        const dFm = moment(d).local().format('MM/DD/YYYY');
        if (invalidDays.includes(dFm)) return;
        deliveryHours[moment(d).local().format('YYYY-MM-DD')] = [
          {
            from: '09:00',
            to: '21:00',
          },
        ];
      });

      return setFieldValue('delivery_hours', deliveryHours);
    }
    return setFieldValue('delivery_hours', {});
  };

  return (
    <Modal
      title={!isEdit ? 'Add Special Hours' : 'Edit Special Hours'}
      open={open}
      onClose={onClose}
      disableCloseOutside
      PaperProps={{
        sx: {
          width: 650,
        },
      }}
      maxWidth='lg'
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'create-special-hours',
          id: 'create-special-hours-btn',
          loading: false,
          disabled: !!errors.filter((err) => !!err.error).length,
        },
      ]}
    >
      <Formik
        initialValues={
          isEdit
            ? {
                ...specialHours,
                start_date: moment(specialHours?.start_date)
                  .local()
                  .format('MM/DD/YYYY'),
                end_date: moment(specialHours?.end_date)
                  .local()
                  .format('MM/DD/YYYY'),
              }
            : {
                name: '',
                start_date: '',
                end_date: '',
                delivery_hours: {},
                delivery_window: 15,
                next_available_time: 30,
              }
        }
        onSubmit={onSubmit}
        validationSchema={AddSpecialHours}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form
            style={{ minHeight: 600 }}
            id='create-special-hours'
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field.TextInput name='name' legend='Name' />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Field.DateSelector
                  legend='Start Date'
                  name='start_date'
                  dateFormat
                  disablePastDate
                  timeFormat={false}
                  invalidDays={invalidDays}
                  onApply={(formVal, val) => {
                    handleApplyDate(val, values.end_date, setFieldValue);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Field.DateSelector
                  name='end_date'
                  legend='End Date'
                  dateFormat
                  disablePastDate
                  invalidDays={invalidDays}
                  onApply={(formVal, val) => {
                    handleApplyDate(values.start_date, val, setFieldValue);
                  }}
                />
              </Grid>
            </Grid>
            {dateError ? (
              <FormHelperText error>{dateError}</FormHelperText>
            ) : null}
            <Stack>
              {values.start_date &&
              values.end_date &&
              moment(values.start_date).unix() <=
                moment(values.end_date).unix() ? (
                <>
                  <DeliveryHoursSelect
                    errors={errors}
                    setErrors={setErrors}
                    data={convertedSpecialHours(values.delivery_hours)}
                    onChange={(d: DeliveryHoursSelectItem) => {
                      setFieldValue('delivery_hours', {
                        ...values.delivery_hours,
                        ...{ [d.weekdayValue]: d.hours },
                      });
                    }}
                  />
                  <Divider sx={{ my: 2 }} />
                  <Typography variant='h5' color={themes.color.black}>
                    Time Slot
                  </Typography>
                  <Typography>
                    Set the time slots that customer can select for pickup.
                  </Typography>

                  <Field.Select
                    fullWidth={false}
                    name='delivery_window'
                    options={GAP_TIME_OPTIONS.map((o) => ({
                      value: o.value,
                      label: `${o.label} each`,
                    }))}
                    formControlProps={{
                      sx: { width: '50%', mt: 1 },
                    }}
                  />

                  <Divider sx={{ my: 2 }} />
                  <Typography variant='h5' color={themes.color.black}>
                    Next Available Time
                  </Typography>
                  <Typography>
                    Set the length of time it takes to complete a pickup order,
                    which will determine the next available time a customer can
                    place an order.
                  </Typography>

                  <Field.Select
                    fullWidth={false}
                    name='next_available_time'
                    options={GAP_TIME_OPTIONS}
                    formControlProps={{
                      sx: { width: '50%', mt: 1 },
                    }}
                  />
                </>
              ) : (
                <Typography
                  mt={5}
                  textAlign='center'
                  color={themes.color.gray600}
                >
                  Please select a start and end date to begin scheduling special
                  hours.
                </Typography>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
