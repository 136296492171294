import {
  Box,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import themes from '~/themes';
import CheckBox from '~/components/common/CheckBox';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useCallback } from 'react';
import moment from 'moment';
import { Icon } from '~/components/common/Icon';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { HoursSelect } from './HoursSelect';

export type SameDeliveryHoursSelectItem = {
  weekdayValue: string;
  weekDayLabel: string;
  hours: { from: string; to: string }[];
  is24Hour: boolean;
  isClosed: boolean;
};
export type HourError = {
  id: string;
  error: string;
};
type SameDeliveryHoursSelectProps = {
  data: Array<SameDeliveryHoursSelectItem>;
  onChange: (item?: SameDeliveryHoursSelectItem) => void;
  errors: HourError[];
  setErrors: (errs: any) => void;
};

export const SameDeliveryHoursSelect = (
  props: SameDeliveryHoursSelectProps,
) => {
  const { data, errors, setErrors, onChange } = props;
  const isShowOrderLimit = useFeatureIsOn('bs-time-slot-phase1');

  const handleSetErrorHours = useCallback(
    (id: string, item: { from: string; to: string }) => {
      let error = '';
      if (
        (item.from === '12:00' && item.to === '12:00') ||
        moment(item.to, 'hh:mm').isSameOrBefore(moment(item.from, 'hh:mm'))
      ) {
        error = 'Start time must be before end time';
      }
      setErrors((errs) =>
        errs.length && errs.find((err) => err.id === id)
          ? errs.map((err) => (err.id === id ? { id, error } : err))
          : [...errs, { id, error }],
      );
    },
    [],
  );

  const getMoreOptions = (params: {
    is24Hour?: boolean;
    isAddTimeFrame?: boolean;
  }) => {
    const { is24Hour, isAddTimeFrame } = params;
    const opts = [];
    if (isAddTimeFrame && !is24Hour) {
      opts.push({
        label: 'Add time frame',
        value: 'add-time-frame',
      });
    }
    if (is24Hour) {
      opts.push({
        label: 'Set Hours',
        value: 'set-hours',
      });
    } else {
      opts.push({
        label: 'Open 24 Hours',
        value: 'open-24hour',
      });
    }

    return opts;
  };

  const convertH2M = (timeInHour) => {
    const timeParts = timeInHour.split(':');
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  };

  const getAddTimeFrameDefault = (hours: { from: string; to: string }[]) => {
    const time = { from: '', to: '21:00' };
    const maxHours = hours.map((hour) => moment(hour.to, 'hh:mm'));
    time.from = moment.max(maxHours).add(15, 'minutes').format('HH:mm');
    // check if max hours > 9:00 PM
    if (convertH2M(moment.max(maxHours).format('HH:mm')) >= 1260) {
      time.to = '23:59';
    }

    return time;
  };

  const isEnableAddTimeFrame = (hours: { from: string; to: string }[]) => {
    if (!isShowOrderLimit) return false;
    if (hours.length >= 3) return false;
    const maxHours = hours.map((hour) => moment(hour.to, 'hh:mm'));

    return convertH2M(moment.max(maxHours).format('HH:mm')) <= 1410;
  };

  const dataItem =
    data.find((item) => item.hours[0].from && item.hours[0].from) || data[0];

  return (
    <Stack
      spacing={1}
      mt={2}
      direction='row'
      sx={{
        borderRadius: '5px',
        px: 2,
        py: 1,
        background: themes.bg.lightPurple,
      }}
    >
      <Stack>
        {data.map((item) => (
          <Box sx={{ width: 120, minHeight: 42 }}>
            <CheckBox
              id='delivery-hours'
              checked={!item.isClosed}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  if (dataItem.hours[0].from && dataItem.hours[0].to) {
                    onChange({
                      ...dataItem,
                      weekDayLabel: item.weekDayLabel,
                      weekdayValue: item.weekdayValue,
                    });
                  } else {
                    onChange({
                      ...item,
                      hours: [{ from: '09:00', to: '21:00' }],
                    });
                  }
                } else {
                  onChange({ ...item, hours: [{ from: '', to: '' }] });
                }
              }}
              defaultCheckedIcon
              label={item.weekDayLabel}
              sx={{
                '& svg': {
                  background: 'white',
                },
              }}
            />
          </Box>
        ))}
      </Stack>
      <Divider sx={{ borderLeftWidth: 1 }} />
      {dataItem.is24Hour || dataItem.isClosed ? (
        <Typography
          data-testid={dataItem.isClosed ? 'closed' : 'open-24-hours'}
          sx={{
            minWidth: 324,
            py: 0.6,
            marginLeft: '36px',
            border: '1px solid #BFC3E0',
            borderRadius: '18px',
            background: 'white',
            textAlign: 'center',
            height: 22,
          }}
        >
          {dataItem.isClosed ? 'Closed' : 'Open 24 hours'}
        </Typography>
      ) : (
        <Stack spacing={1}>
          {(dataItem?.hours || []).map((hourItem, hoursIdx) => {
            const error = errors?.find(
              (e) => e.id === `${dataItem.weekdayValue}-${hoursIdx}`,
            );
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Stack key={`${dataItem.weekdayValue}-${hoursIdx}`}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  {hoursIdx === 0 ? (
                    <Box width={26} />
                  ) : (
                    <IconButton
                      onClick={() => {
                        onChange({
                          ...dataItem,
                          hours: dataItem.hours.filter(
                            (_, i) => hoursIdx !== i,
                          ),
                        });
                        setErrors((err) =>
                          err.filter(
                            (e) =>
                              e.id !== `${dataItem.weekdayValue}-${hoursIdx}`,
                          ),
                        );
                      }}
                    >
                      <Icon
                        name='close'
                        useBackgroundImg
                        size={10}
                        color={themes.color.violet900}
                      />
                    </IconButton>
                  )}
                  <HoursSelect
                    timeGap={15}
                    value={hourItem.from}
                    id='from-hours'
                    minMinutesTimeSlot={
                      hoursIdx > 0 && dataItem.hours[hoursIdx - 1]?.to
                        ? convertH2M(dataItem.hours[hoursIdx - 1].to)
                        : null
                    }
                    onChange={(e: any) => {
                      const { value } = e.target;
                      onChange({
                        ...dataItem,
                        hours: dataItem.hours.map((hour, i) => {
                          if (hoursIdx === i) {
                            return { ...hour, from: value };
                          }
                          return hour;
                        }),
                      });

                      handleSetErrorHours(
                        `${dataItem.weekdayValue}-${hoursIdx}`,
                        {
                          from: value,
                          to: hourItem.to,
                        },
                      );
                    }}
                  />
                  <Typography>-</Typography>
                  <HoursSelect
                    timeGap={15}
                    value={hourItem.to}
                    maxMinutesTimeSlot={
                      dataItem.hours[hoursIdx + 1]?.from
                        ? convertH2M(dataItem.hours[hoursIdx + 1].from)
                        : null
                    }
                    id='to-hours'
                    onChange={(e: any) => {
                      const { value } = e.target;
                      onChange({
                        ...dataItem,
                        hours: dataItem.hours.map((hour, i) =>
                          hoursIdx === i ? { ...hour, to: value } : hour,
                        ),
                      });
                      handleSetErrorHours(
                        `${dataItem.weekdayValue}-${hoursIdx}`,
                        {
                          from: hourItem.from,
                          to: value,
                        },
                      );
                    }}
                  />
                </Stack>

                {error?.error ? (
                  <FormHelperText
                    error={!!error.error}
                    data-testid='select-hours-error'
                    sx={{
                      pl: 3.6,
                    }}
                  >
                    {error.error}
                  </FormHelperText>
                ) : null}
              </Stack>
            );
          })}
        </Stack>
      )}
      {dataItem.isClosed ? (
        <MoreHorizIcon
          sx={{
            color: themes.color.violet900,
            width: 40,
            height: 24,
            mr: 0.3,
          }}
        />
      ) : (
        <ElementSelect
          iconButtonProps={{
            sx: {
              width: 40,
              height: 40,
            },
          }}
          elementSelect={() => (
            <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
          )}
          onChange={(o) => {
            if (o.value === 'open-24hour') {
              onChange({
                ...dataItem,
                hours: [{ from: '00:00', to: '24:00' }],
              });
            }
            if (o.value === 'set-hours') {
              onChange({
                ...dataItem,
                hours: [{ from: '09:00', to: '21:00' }],
              });
            }

            if (o.value === 'add-time-frame') {
              onChange({
                ...dataItem,
                hours: [
                  ...dataItem.hours,
                  getAddTimeFrameDefault(dataItem.hours),
                ],
              });
            }
          }}
          options={getMoreOptions({
            is24Hour: dataItem.is24Hour,
            isAddTimeFrame: isEnableAddTimeFrame(dataItem.hours),
          })}
        />
      )}
    </Stack>
  );
};
