import {
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useCallback, useMemo, useRef, useState } from 'react';
import Modal from '~/components/common/Modal';
import { TextInput } from '~/components/common/TextInput';
import { weekdays } from '~/constants/stores';
import {
  DeliveryHours,
  IStore,
  IndividualTimeSlotsType,
} from '~/models/stores';
import { usePreviewTimeSlot } from '~/services/api/stores';
import themes from '~/themes';
import { IndividualOrderLimitSchema } from '~/utils/schema/stores';

type DialogIndividualTimeSlotsProps = {
  open?: boolean;
  stores?: IStore;
  onClose: () => void;
  onSaveDataSetLimit: (values: DeliveryHours) => void;
};

export const DialogIndividualTimeSlots: React.FC<
  DialogIndividualTimeSlotsProps
> = ({ open, stores, onClose, onSaveDataSetLimit }) => {
  const orderLimitAllInputRef = useRef(null);
  const orderLimitTimeSlotInputRef = useRef(null);
  const { data: deliveryData, isFetching: isFetchingPreviewTimeSlot } =
    usePreviewTimeSlot({
      params: stores,
    });
  const deliveryHoursPreview = deliveryData.delivery_hours;
  const [errors, setErrors] = useState<
    {
      id: string;
      errMsg: string;
      type: IndividualTimeSlotsType;
    }[]
  >([]);

  const convertDeliveryHours = (dHours) => {
    if (!dHours) return {};
    return Object.keys(dHours).reduce((acc, key) => {
      const hours = dHours[key];
      const timeSlots = hours.map(({ time_slot }) => time_slot || []).flat();
      let type = IndividualTimeSlotsType.LimitIndividualSlot;
      if (!hours.some((e) => e.limit) && !timeSlots.some((e) => e.limit))
        type = IndividualTimeSlotsType.LimitAllSlot;
      if (hours.some((e) => e.limit))
        type = IndividualTimeSlotsType.LimitAllSlot;
      acc[key] = hours.map((e) => ({ ...e, type }));
      return acc;
    }, {});
  };

  const getTimeSlots = (dHours, weekday) => {
    if (!dHours) return [];
    return dHours[weekday]
      ? dHours[weekday]
          .map(({ time_slot }) => time_slot || [])
          .flat()
          .map((slot) => ({
            label: `${moment(slot.from, 'HH:mm').format('hh:mm a')} - ${moment(
              slot.to,
              'HH:mm',
            ).format('hh:mm a')}`,
            value: `${weekday}@${slot.from} - ${slot.to}`,
            limit: slot.limit || null,
          }))
      : [];
  };

  const getTimeSlotSelected = (dHours, weekday) =>
    getTimeSlots(dHours, weekday)[0]?.value || '';

  const updateDeliveryHoursField = (
    setFieldValue,
    values,
    updateValues: {
      allLimit?: number;
      updateTimeSlotKey?: string;
      timeSlotLimit?: number;
      type?: IndividualTimeSlotsType;
    },
  ) => {
    const { allLimit, updateTimeSlotKey, timeSlotLimit } = updateValues;

    setFieldValue('deliveryHours', {
      ...values.deliveryHours,
      [values.weekday]: values.deliveryHours[values.weekday].map((e) => ({
        ...e,
        limit: allLimit || null,
        ...(updateValues.type && { type: updateValues.type }),
        ...(updateTimeSlotKey && {
          time_slot: e?.time_slot?.length
            ? e.time_slot.map((slot) =>
                `${values.weekday}@${slot.from} - ${slot.to}` ===
                updateTimeSlotKey
                  ? { ...slot, limit: timeSlotLimit || null }
                  : slot,
              )
            : [],
        }),
      })),
    });
  };

  const validateSyncLimit = (id, limit, type?: IndividualTimeSlotsType) => {
    try {
      IndividualOrderLimitSchema.validateSync({ limit: limit || null });
      setErrors(errors.filter((e) => e.id !== id && e.type === type));
    } catch (e) {
      const errMsg = e?.message || '';
      setErrors(
        errors.length && errors.find((err) => err.id === id)
          ? errors.map((err) => (err.id === id ? { id, errMsg, type } : err))
          : [...errors, { id, errMsg, type }],
      );
    }
  };

  const handleSubmit = (values) => {
    const params = Object.keys(values.deliveryHours).reduce((acc, key) => {
      const hours = values.deliveryHours[key];
      acc[key] = hours.map((e) => {
        if (e.type === IndividualTimeSlotsType.LimitAllSlot) {
          return {
            from: e.from,
            to: e.to,
            limit: e.limit,
            ...(e?.time_slot && {
              time_slot: e.time_slot.map((slot) => ({
                from: slot.from,
                to: slot.to,
                limit: null,
              })),
            }),
          };
        }
        return {
          from: e.from,
          to: e.to,
          limit: null,
          time_slot: e?.time_slot || [],
        };
      });
      return acc;
    }, {});

    onSaveDataSetLimit(params);
    onClose();
  };

  const weekDaysComponent = useCallback(
    (values, setFieldValue) => (
      <Stack direction='row' spacing={1}>
        {weekdays.map(({ label, value }) => (
          <Stack
            key={label}
            sx={{
              width: 100,
              height: 60,
              justifyContent: 'center',
              alignItems: 'center',
              background: themes.bg.lightPurple,
              color: themes.color.violet900,
              fontWeight: 'bold',
              borderRadius: '5px',
              cursor: 'pointer',
              ...(value === values.weekday && {
                background: themes.bg.darkPurple,
                color: themes.color.white,
              }),
            }}
            onClick={() => {
              setFieldValue('weekday', value);
              setFieldValue(
                'timeSlotSelected',
                getTimeSlotSelected(values.deliveryHours, value),
              );
            }}
          >
            {label}
          </Stack>
        ))}
      </Stack>
    ),
    [],
  );

  const individualTimeSlotsComponent = (values, setFieldValue) => {
    const timeSlots = getTimeSlots(values.deliveryHours, values.weekday);
    if (!timeSlots.length) return <Typography>No time slot data.</Typography>;

    const { timeSlotSelected } = values;
    const orderLimitValue = values.deliveryHours[values.weekday]
      .find((h) =>
        h?.time_slot?.some(
          (s) => `${values.weekday}@${s.from} - ${s.to}` === timeSlotSelected,
        ),
      )
      ?.time_slot.find(
        (s) => `${values.weekday}@${s.from} - ${s.to}` === timeSlotSelected,
      )?.limit;

    const error = errors?.find((e) => e.id === timeSlotSelected);

    if (!timeSlots.length) return <Typography>No time slot data.</Typography>;
    return (
      <Grid container columnSpacing={2}>
        <Grid
          item
          lg={4}
          xs={6}
          sx={{
            borderRight: '1px solid #E9E7F6',
            height: '100%',
          }}
        >
          <Typography variant='h5' color={themes.color.black}>
            Time Slots
          </Typography>
          <Stack
            maxHeight={500}
            mt={1}
            className='customized-scrollbar'
            sx={{
              overflowY: 'auto',
            }}
          >
            {timeSlots.map((opt) => (
              <Typography
                mb={1}
                mr={2}
                padding={themes.spacing(1, 2)}
                onClick={() => {
                  setTimeout(
                    () => orderLimitTimeSlotInputRef.current.focus(),
                    0,
                  );
                  setFieldValue('timeSlotSelected', opt.value);
                }}
                sx={{
                  borderRadius: '5px',
                  background: themes.bg.lightPurple,
                  cursor: 'pointer',
                  ...(timeSlotSelected === opt.value && {
                    background: themes.bg.darkPurple,
                    color: themes.color.white,
                  }),
                }}
                key={opt.value}
              >
                {opt.label}
              </Typography>
            ))}
          </Stack>
        </Grid>
        <Grid item lg={8} xs={6}>
          <Typography variant='h5' color={themes.color.black}>
            Order Limit
          </Typography>
          <TextInput
            value={orderLimitValue || ''}
            error={!!error?.errMsg}
            type='number'
            id={IndividualTimeSlotsType.LimitIndividualSlot}
            inputRef={orderLimitTimeSlotInputRef}
            inputProps={{ autoFocus: true, min: 1, step: 'any' }}
            onChange={(event) => {
              const value = parseInt(event.target.value, 10);
              updateDeliveryHoursField(setFieldValue, values, {
                allLimit: values.deliveryHours[values.weekday][0]?.limit,
                updateTimeSlotKey: timeSlotSelected,
                timeSlotLimit: value || null,
              });
              validateSyncLimit(
                timeSlotSelected,
                value,
                IndividualTimeSlotsType.LimitIndividualSlot,
              );
            }}
            sx={{
              width: 260,
              mt: 1,
              [themes.breakpoints.down('md')]: { width: '100%' },
            }}
          />
          {error?.errMsg ? (
            <FormHelperText
              error={!!error?.errMsg}
              data-testid='order-limit-time-slot-error'
            >
              {error?.errMsg}
            </FormHelperText>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const loadingComponent = useMemo(
    () => (
      <Stack alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    ),
    [],
  );

  const limitSlotForm = () => {
    if (!deliveryHoursPreview)
      return <Typography>No delivery hours data.</Typography>;
    return (
      <Formik
        initialValues={{
          weekday: 'sunday',
          deliveryHours: convertDeliveryHours(deliveryHoursPreview),
          timeSlots: getTimeSlots(deliveryHoursPreview, 'sunday'),
          timeSlotSelected: getTimeSlotSelected(deliveryHoursPreview, 'sunday'),
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          const error = errors?.find((e) => e.id === values.weekday);
          const individualTimeSlotsType =
            values.deliveryHours[values.weekday][0]?.type ||
            IndividualTimeSlotsType.LimitAllSlot;
          return (
            <Form id='individual_time_slots'>
              {weekDaysComponent(values, setFieldValue)}
              <RadioGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  label: {
                    width: 'fit-content',
                  },
                }}
                onChange={(event) => {
                  const { value } = event.target;
                  updateDeliveryHoursField(setFieldValue, values, {
                    allLimit: values.deliveryHours[values.weekday][0]?.limit,
                    timeSlotLimit: null,
                    updateTimeSlotKey: null,
                    type: value as IndividualTimeSlotsType,
                  });
                  setFieldValue(
                    'timeSlotSelected',
                    getTimeSlotSelected(deliveryHoursPreview, values.weekday),
                  );

                  setTimeout(() => {
                    orderLimitAllInputRef?.current?.focus();
                    orderLimitTimeSlotInputRef?.current?.focus();
                  }, 0);

                  setErrors((err) => err.filter((e) => e.type === value));

                  if (value === IndividualTimeSlotsType.LimitAllSlot) {
                    validateSyncLimit(
                      values.weekday,
                      values.deliveryHours[values.weekday][0]?.limit,
                      value as IndividualTimeSlotsType,
                    );
                  } else {
                    const timeSlots = getTimeSlots(
                      values.deliveryHours,
                      values.weekday,
                    );
                    timeSlots
                      .filter((e) => e.limit)
                      .forEach((e) => {
                        validateSyncLimit(
                          e.value,
                          e.limit,
                          value as IndividualTimeSlotsType,
                        );
                      });
                  }
                }}
                value={individualTimeSlotsType}
              >
                <FormControlLabel
                  value={IndividualTimeSlotsType.LimitAllSlot}
                  control={<Radio />}
                  label='Set for all time slots'
                />
                {individualTimeSlotsType ===
                  IndividualTimeSlotsType.LimitAllSlot && (
                  <TextInput
                    type='number'
                    error={!!error?.errMsg}
                    id={IndividualTimeSlotsType.LimitAllSlot}
                    inputProps={{ autoFocus: true, min: 1, step: 'any' }}
                    inputRef={orderLimitAllInputRef}
                    sx={{
                      width: 260,
                    }}
                    value={values.deliveryHours[values.weekday][0]?.limit || ''}
                    onChange={(event) => {
                      const value = parseInt(event.target.value, 10);
                      updateDeliveryHoursField(setFieldValue, values, {
                        allLimit: value || null,
                      });
                      validateSyncLimit(
                        values.weekday,
                        value,
                        IndividualTimeSlotsType.LimitAllSlot,
                      );
                    }}
                  />
                )}
                {error?.errMsg &&
                individualTimeSlotsType ===
                  IndividualTimeSlotsType.LimitAllSlot ? (
                  <FormHelperText
                    error={!!error?.errMsg}
                    data-testid='order-limit-all-error'
                  >
                    {error?.errMsg}
                  </FormHelperText>
                ) : null}

                <FormControlLabel
                  value={IndividualTimeSlotsType.LimitIndividualSlot}
                  control={<Radio />}
                  label='Set for individual time slots'
                />
                {individualTimeSlotsType ===
                  IndividualTimeSlotsType.LimitIndividualSlot &&
                  individualTimeSlotsComponent(values, setFieldValue)}
              </RadioGroup>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={
        <Typography fontWeight='bold' fontSize={22}>
          Set Limit
        </Typography>
      }
      disableCloseOutside
      PaperProps={{
        sx: {
          'maxWidth': 800,
          '& .MuiDialogContent-root': {
            overflow: 'hidden',
          },
        },
      }}
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'individual_time_slots',
          id: 'individual_time_slots',
          loading: false,
          disabled: !deliveryHoursPreview || !!errors.length,
        },
      ]}
    >
      {isFetchingPreviewTimeSlot ? loadingComponent : limitSlotForm()}
    </Modal>
  );
};
