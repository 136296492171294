/* eslint-disable camelcase */
import queryString from 'query-string';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import moment, { CalendarSpec, Moment } from 'moment';

export const removeEmptyParams = (query: string) =>
  query.replace(/[^=&]+=(?:&|$)/g, '');

export const updateLocationSearchParams = (
  location: { [key: string]: any },
  paramsUpdate: object,
  options: any = null,
) => {
  const parsed = queryString.parse(location.search);
  Object.assign(parsed, paramsUpdate);
  return queryString.stringify(
    parsed,
    options || {
      skipNull: true,
      skipEmptyString: true,
    },
  );
};

export const removeLastCharacterURL = (url: string) => url.replace(/\/$/, '');

export const formatPhoneNumber = (
  phone: string,
  defaultCountry?: CountryCode,
) => {
  if (!phone || !phone.length) return '';
  const phoneNumber = parsePhoneNumber(phone, defaultCountry || 'CA');
  if (phoneNumber) {
    const phoneArray = phoneNumber
      .format('INTERNATIONAL')
      ?.replaceAll(' ', '-')
      .replace('-', ' ')
      .split(' ');
    return `(${phoneArray[0]}) ${phoneArray[1]}`;
  }
  return phone;
};

export const convertShortString = (
  text?: string,
  maxLength: number = 20,
): string => {
  if (!text) return '';
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.slice(0, maxLength)}...`;
};

export const formatCurrency = (cost, currency = '$') => {
  if (!cost) return `${currency}0`;
  if (cost.toString().includes('-'))
    return `-${currency}${cost.toString().replace(/^-/, '')}`;
  return `${currency}${cost}`;
};
const endpoint = ['M', 'B', 'T'];

const convertToText = (num, pointnums) => {
  let formatNum = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(num);
  let int_part = +parseFloat(formatNum.split('.')[0].replace(/,/g, ''));
  if (int_part === 1000 && pointnums < 2) {
    return `1${endpoint[pointnums + 1]}`;
  }
  if (int_part.toString().length > 3) {
    int_part = +new Intl.NumberFormat().format(Number(int_part));
  }
  const float_part = +formatNum.split('.')[1];
  if (+float_part === 0) {
    formatNum = String(int_part);
  }
  return `${formatNum}${endpoint[pointnums]}`;
};

export const getNumberWithCurrency = (num, defaultValue = '$0.00') => {
  if (!num) {
    return {
      display: defaultValue,
      tooltip: '',
    };
  }
  const n = Number.parseFloat(num).toFixed(2);
  const int_part = +n.split('.')[0];
  let float_part = n.split('.')[1];
  if (int_part < 1000) {
    return {
      display: formatCurrency(
        `${new Intl.NumberFormat().format(Number(int_part))}.${float_part}`,
      ),
      tooltip: '',
    };
  }
  let val;
  if (!float_part) {
    float_part = '00';
  }
  if (+int_part < 1000000) {
    val = `${new Intl.NumberFormat().format(Number(int_part))}`;
  } else if (+int_part >= 1000000 && +int_part < 1000000000) {
    val = convertToText(+int_part / 1000000, 0);
  } else if (+int_part >= 1000000000 && +int_part < 1000000000000) {
    val = convertToText(+int_part / 1000000000, 1);
  } else {
    val = convertToText(+int_part / 1000000000000, 2);
  }
  return {
    display: formatCurrency(`${val}`),
    tooltip: formatCurrency(
      `${new Intl.NumberFormat().format(Number(int_part))}.${float_part}`,
    ),
  };
};

const getAddressComponent = (address_components: any, label: string) =>
  (
    (address_components || []).find(
      (x: any) => x.types && x.types[0] === label,
    ) || { short_name: '' }
  ).short_name;

export const convertGoogleLocationDetail = ({
  place_id,
  name,
  vicinity,
  address_components,
  international_phone_number,
}: any) => ({
  place_id,
  name,
  address_1:
    getAddressComponent(address_components, 'street_number') &&
    getAddressComponent(address_components, 'route')
      ? // eslint-disable-next-line max-len
        `${getAddressComponent(
          address_components,
          'street_number',
        )} ${getAddressComponent(address_components, 'route')}`
      : vicinity || name,
  postal_code: getAddressComponent(address_components, 'postal_code'),
  country: getAddressComponent(address_components, 'country'),
  state: getAddressComponent(address_components, 'administrative_area_level_1'),
  city: getAddressComponent(address_components, 'locality'),
  phone: international_phone_number,
});

export const formatPrice = (
  price: number | undefined,
  options?: {
    currency?: string;
    billingCycle?: string;
    displayZeroNumber?: boolean;
    displayZeroCurrency?: boolean;
    minimumFractionDigits?: number;
    hasCurrencyPrefix?: boolean;
  },
) => {
  const {
    displayZeroNumber = false,
    displayZeroCurrency = true,
    minimumFractionDigits = 0,
    hasCurrencyPrefix = false,
    currency = 'USD',
  } = options || {};
  if (!price) {
    if (displayZeroNumber) return '$0';
    if (displayZeroCurrency && hasCurrencyPrefix) return `$0.00 ${currency}`;
    return '$0.00';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  });
  if (hasCurrencyPrefix) {
    return `${formatter.format(price)} ${currency}`;
  }
  return formatter.format(price);
};

export const capitalizeFLetter = (string) => {
  if (!string) return '';
  return string[0].toUpperCase() + string.slice(1);
};

export const getStateNameByStateCodeAndCountry = (
  countries,
  countryCode,
  stateCode,
) => {
  if (countries && countryCode && stateCode) {
    const s =
      countries.find(({ value }: any) => value === countryCode)?.states || [];
    const stateLabel = s.find(({ value }) => value === stateCode)?.label || '';
    return stateLabel;
  }
  return '';
};

export const formatSuggestionsAddress = ({
  query,
  country,
  state,
}: {
  query: string;
  country?: string;
  state?: string;
}) => [query, state, country].filter((e) => !!e).join(', ');

export const formatDateTimeUTC = (
  date?: string | Date | Moment,
  format: string = 'hh:mm a',
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).format(
        format,
      )
    : '-';

export const formatTaskDateTime = (
  date?: string | Date | Moment,
  formats?: CalendarSpec,
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).calendar(
        null,
        {
          sameDay: 'h:mm a | [Today]',
          nextDay: 'h:mm a | [Tmrw]',
          lastDay: 'h:mm a | [Ystd]',
          nextWeek: 'hh:mm a | MM/DD',
          lastWeek: 'hh:mm a | MM/DD',
          sameElse: 'hh:mm a | MM/DD',
          ...formats,
        },
      )
    : 'ASAP';

export const formatDeliveryDateTimeTask = (
  {
    beforeDate,
    afterDate,
  }: {
    beforeDate?: string | Date | Moment;
    afterDate?: string | Date | Moment;
  },
  formats?: CalendarSpec,
) => {
  if (!beforeDate && !afterDate) return 'ASAP';

  const formatBeforeDate = moment(
    `${moment(beforeDate).format('YYYY-MM-DDTHH:mm:ss+00:00')}`,
  );
  const current = moment();
  const isSameYear = formatBeforeDate.isSame(current, 'year');
  const formatDate = isSameYear ? 'MM/DD' : 'MM/DD/YYYY';

  if (!afterDate && beforeDate)
    return formatTaskDateTime(beforeDate, {
      sameDay: '[ASAP] | [Today]',
      nextDay: '[ASAP] | [Tmrw]',
      lastDay: '[ASAP] | [Ystd]',
      nextWeek: `[ASAP] | ${formatDate}`,
      lastWeek: `[ASAP] | ${formatDate}`,
      sameElse: `[ASAP] | ${formatDate}`,
      ...formats,
    });

  return formatTaskDateTime(beforeDate, {
    sameDay: 'h:mm a | [Today]',
    nextDay: 'h:mm a | [Tmrw]',
    lastDay: 'h:mm a | [Ystd]',
    nextWeek: `hh:mm a | ${formatDate}`,
    lastWeek: `hh:mm a | ${formatDate}`,
    sameElse: `hh:mm a | ${formatDate}`,
    ...formats,
  });
};
