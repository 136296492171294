import moment from 'moment';
import { object, string, number } from 'yup';
import { Zone } from '~/models/stores';

export const AddZoneTypeSchema = object().shape({
  area_type: string(),
});

export const AddZoneSchema = object().shape({
  name: string().required('Please enter a Zone Name'),
  delivery_fee: number()
    .required('Please enter a Delivery Fee')
    .test('delivery_fee', 'Please enter a Delivery Fee', (value) => value >= 0),
  delivery_minimum: number()
    .required('Please enter a Order Minimum')
    .test(
      'delivery_minimum',
      'Please enter a Order Minimum',
      (value) => value >= 0,
    ),
});

export const AddPostalCodeSchema = object().shape({
  postal_code: string().required('Please enter a Postal Code'),
  delivery_fee: number()
    .required('Please enter a Delivery Fee')
    .test('delivery_fee', 'Please enter a Delivery Fee', (value) => value >= 0),
  delivery_minimum: number()
    .required('Please enter a Order Minimum')
    .test(
      'delivery_minimum',
      'Please enter a Order Minimum',
      (value) => value >= 0,
    ),
});

export const AddDistanceSchema = (zones: Zone[]) =>
  object().shape({
    max_delivery_distance: number()
      .required('Please enter Max Delivery Distance')
      .test(
        'max_delivery_distance',
        'Please enter a Max Delivery Distance',
        (value) => value > 0,
      ),
    delivery_fee: number()
      .default(0)
      .test(
        'delivery_fee',
        'Please enter a Delivery Fee',
        (value) => !value || value >= 0,
      ),
    delivery_minimum: number()
      .default(0)
      .test('delivery_minimum', function (value, ctx) {
        if (value < 0)
          return ctx.createError({ message: 'Please enter a Order Minimum' });
        const hasExistZone = zones?.some(
          (zone) =>
            zone?.max_delivery_distance_type ===
              this.parent.max_delivery_distance_type &&
            zone.max_delivery_distance === this.parent.max_delivery_distance &&
            zone.delivery_minimum === value,
        );
        if (hasExistZone)
          return ctx.createError({
            message:
              'Enter a different value, this delivery minimum already exists under the same radius.',
          });

        return true;
      }),
  });

export const AddSpecialHours = object().shape({
  name: string().trim().required('Please enter a Name'),
  start_date: string()
    .required('Please enter a Start Date')
    .test(
      'start_date',
      'Start date must be before end date.',
      // eslint-disable-next-line func-names
      function (value) {
        return moment(this.parent.end_date).isSameOrAfter(moment(value));
      },
    ),
  end_date: string()
    .required('Please enter an End Date')
    // eslint-disable-next-line func-names
    .test('end_date', 'End date must be after start date.', function (value) {
      return moment(this.parent.start_date).isSameOrBefore(moment(value));
    }),
});
