import { URLS } from '~/config';
import { IOptionItem } from '~/models/common';
import { transformTypeOptions } from '~/utils/common';

export const STORES_DRAWER_TAB_VALUE = {
  General: 'General',
  Teams: 'Teams',
  TaskSettings: 'Task Settings',
  DeliveryHours: 'Delivery Hours',
  DeliveryZoneFee: 'Delivery zones & Fees',
};

export const STORES_DRAWER_TABS: IOptionItem[] = transformTypeOptions(
  STORES_DRAWER_TAB_VALUE,
);

export const GAP_TIME_OPTIONS = [
  {
    value: 15,
    label: '15 minutes',
  },
  {
    value: 30,
    label: '30 minutes',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 90,
    label: '1.5 hours',
  },
  {
    value: 120,
    label: '2 hours',
  },
  {
    value: 150,
    label: '2.5 hours',
  },
  {
    value: 180,
    label: '3 hours',
  },
  {
    value: 210,
    label: '3.5 hours',
  },
  {
    value: 240,
    label: '4 hours',
  },
  {
    value: 270,
    label: '4.5 hours',
  },
  {
    value: 300,
    label: '5 hours',
  },
];

export const deliveryHoursDefaultData = {
  monday: [
    {
      from: '09:00',
      to: '21:00',
    },
  ],
  tuesday: [
    {
      from: '09:00',
      to: '21:00',
    },
  ],
  wednesday: [
    {
      from: '09:00',
      to: '21:00',
    },
  ],
  thursday: [
    {
      from: '09:00',
      to: '21:00',
    },
  ],
  friday: [
    {
      from: '09:00',
      to: '21:00',
    },
  ],
  saturday: [
    {
      from: '09:00',
      to: '21:00',
    },
  ],
  sunday: [
    {
      from: '09:00',
      to: '21:00',
    },
  ],
};

export const weekdays = [
  { label: 'Sun', value: 'sunday' },
  { label: 'Mon', value: 'monday' },
  { label: 'Tue', value: 'tuesday' },
  { label: 'Wed', value: 'wednesday' },
  { label: 'Thu', value: 'thursday' },
  { label: 'Fri', value: 'friday' },
  { label: 'Sat', value: 'saturday' },
];
const urls = URLS ? JSON.parse(URLS) : {};

export const CanFleetPluginURL = {
  Woo: urls.wooPlugIn,
  Breadstack: urls.breadstackPlugin,
};

export const importFileUrl = {
  file: urls.importFilePostalCodeUrl,
};
