import {
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import themes from '~/themes';
import { Form, Formik } from 'formik';
import { Field } from '~/components/common/Formik';
import { GAP_TIME_OPTIONS } from '~/constants/stores';
import Button from '~/components/common/Button';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  DateElement,
  DeliveryHourOptions,
  DeliveryHourType,
  DeliveryHours as IDeliveryHours,
  IStore,
  OrderLimitType,
  StorePlatform,
} from '~/models/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { DeliveryHoursSchema } from '~/utils/schema/stores';
import { cloneDeep, isEqual } from 'lodash';
import { showAlert, alertParams } from '~/components/common/Alert';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Icon } from '~/components/common/Icon';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import {
  DeliveryHoursSelect,
  DeliveryHoursSelectItem,
  HourError,
} from './components/DeliveryHoursSelect';
import { DialogAddSpecialHours } from './components/DialogAddSpecialHours';
import { DialogIndividualTimeSlots } from './components/DialogIndividualTimeSlots';
import { SameDeliveryHoursSelect } from './components/SameDeliveryHoursSelect';

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const DEFAULT_DELIVERY_HOURS = weekdays.reduce(
  (result, key) =>
    Object.assign(result, {
      [key]: [{ from: '09:00', to: '21:00' }],
    }),
  {},
);

type DeliveryHoursProps = {
  data: IStore;
  isFetching?: boolean;
};

export const DeliveryHours: React.FC<DeliveryHoursProps> = ({
  data,
  isFetching,
}) => {
  const [openSpecialHours, setOpenSpecialHours] = useState(false);
  const [selectSpecialHours, setSelectSpecialHours] = useState(null);
  const [errors, setErrors] = useState<Array<HourError>>([]);
  const orderLimitAllInputRef = useRef(null);
  const [prevDeliveryHours, setPrevDeliveryHours] = useState(null);

  const location = useLocation();
  const isShowOrderLimitPhase2 = useFeatureIsOn('bs-time-slot-phase2');
  const search = fromQueryString(location.search);
  const platform = search.platform as StorePlatform;
  const [openSetLimitDialog, setOpenSetLimitDialog] = useState(false);

  const { mutate: updateConnectionMutation, isLoading } =
    useUpdateConnectionMutation({
      onSuccess: () => {
        setPrevDeliveryHours(null);
      },
    });

  const convertedDeliveryHours = (deliveryHours) => {
    const weekDays = Object.keys(deliveryHours);
    return weekDays.map((wd) => {
      const hours = deliveryHours[wd];
      const is24Hour =
        hours.length === 1 &&
        hours[0].from === '00:00' &&
        hours[0].to === '24:00';
      const isClosed =
        hours.length === 1 && hours[0].from === '' && hours[0].to === '';
      return {
        weekdayValue: wd,
        weekDayLabel: wd.charAt(0).toUpperCase() + wd.slice(1),
        hours,
        is24Hour,
        isClosed,
      };
    });
  };

  const getInValidDays = useCallback((specialHours) => {
    const dates = (specialHours || []).reduce((acc, item) => {
      let now = item.start_date ? moment(item.start_date) : null;
      const endM = item.end_date ? moment(item.end_date) : null;
      while (now && endM && now.isSameOrBefore(endM)) {
        acc[now.format('MM/DD/YYYY')] = true;
        now = now.add(1, 'days');
      }
      return acc;
    }, {});
    return Object.keys(dates);
  }, []);

  const isEqualDeliveryHoursData = (oldData, newData) => {
    const oldDeliveryHours = cloneDeep(oldData);
    const newDeliveryHours = cloneDeep(newData);

    return isEqual(
      {
        delivery_window: oldDeliveryHours.delivery_window,
        next_available_time: oldDeliveryHours.next_available_time,
      },
      {
        delivery_window: newDeliveryHours.delivery_window,
        next_available_time: newDeliveryHours.next_available_time,
      },
    );
  };

  const hasSetLimitTimeSlots = (deliveryHoursData: IDeliveryHours) => {
    const deliveryHours = cloneDeep(deliveryHoursData);
    const weekDays = Object.keys(deliveryHours);
    let isCheck = false;
    weekDays.forEach((wd) => {
      const hours: DateElement[] = deliveryHours[wd];
      const timeSlots = hours.map(({ time_slot }) => time_slot || []).flat();
      if (hours.some((e) => e.limit) || timeSlots.some((e) => e.limit)) {
        isCheck = true;
      }
    });
    return isCheck;
  };

  const resetDeliveryHoursLimit = (deliveryHours: IDeliveryHours) => {
    const weekDays = Object.keys(deliveryHours);
    weekDays.forEach((wd) => {
      deliveryHours[wd].forEach((e) => {
        e.limit = null;
        e?.time_slot?.forEach((ts) => {
          ts.limit = null;
        });
      });
    });
    return deliveryHours;
  };

  const onSubmit = (values) => {
    if (!isShowOrderLimitPhase2) {
      values.limit_type = OrderLimitType.LimitAllSlot;
    }

    const isShowWarningMsg =
      values.enable_delivery &&
      (values.limit_all_slot || hasSetLimitTimeSlots(values.delivery_hours)) &&
      !isEqualDeliveryHoursData(data.delivery, values);

    if (values.limit_type === OrderLimitType.LimitAllSlot) {
      values.delivery_hours = resetDeliveryHoursLimit(values.delivery_hours);
    } else {
      values.limit_all_slot = null;
    }

    const params = {
      id: data.id,
      enable_delivery: values.enable_delivery,
      ...(values.enable_delivery && {
        delivery: values,
      }),
      ...(values?.limit_type && {
        limit_type: values.limit_type,
      }),
      limit_all_slot: values.limit_all_slot
        ? parseInt(values.limit_all_slot, 10)
        : null,
      delivery_hour_type: values.delivery_hour_type,
    };

    if (isShowWarningMsg) {
      showAlert({
        ...alertParams.warning,
        description:
          'Updating time slot length will reset your delivery schedule configurations.\n Are you sure you want to continue?',
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: () => updateConnectionMutation(params),
      });
    } else {
      updateConnectionMutation(params);
    }
  };

  if (platform === StorePlatform.Breadstack) {
    return (
      <Stack spacing={2}>
        <Typography
          sx={{
            borderRadius: '5px',
            background: themes.bg.lightPurple,
            padding: 1,
          }}
        >
          Allow your customers to schedule specific delivery time windows for
          their packages, based on your store delivery hours.
        </Typography>

        <Button
          noRounder
          buttonType='default'
          sx={{ width: 'fit-content' }}
          onClick={() => window.open(data.breadstack_url, '_blank')}
        >
          Manage in Breadstack
        </Button>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} pb={1}>
      <Typography
        sx={{
          borderRadius: '5px',
          background: themes.bg.lightPurple,
          padding: 1,
        }}
      >
        Allow your customers to schedule specific delivery time windows for
        their packages, based on your store delivery hours.
      </Typography>
      <Formik
        initialValues={{
          delivery_window: 15,
          next_available_time: 30,
          ...data.delivery,
          enable_delivery: data.enable_delivery || false,
          limit_all_slot: data?.limit_all_slot || null,
          delivery_hour_type:
            data?.delivery?.delivery_hour_type || DeliveryHourType.Different,
          delivery_hours: data.delivery?.delivery_hours,
          special_hours: data.delivery?.special_hours || [],
          limit_type: data?.limit_type || OrderLimitType.LimitAllSlot,
        }}
        validationSchema={DeliveryHoursSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isValid, dirty }) => (
          <Form>
            <div>
              <Typography variant='h5' mb={0.5} color={themes.color.black}>
                Enable Delivery Hours
              </Typography>
              <Field.SwitchButton noText name='enable_delivery' />
            </div>
            {values.enable_delivery ? (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant='h5' color={themes.color.black}>
                  Delivery Hours
                </Typography>
                <GroupRadioButton
                  name='delivery_hour_type'
                  sx={{
                    'ml': -0.5,
                    'mt': 1,
                    'gap': '5px 0px !important',
                    '& > label': {
                      border: 'none',
                      padding: 0.5,
                      fontWeight: 'normal',
                    },
                  }}
                  value={values.delivery_hour_type}
                  onChange={(e) => {
                    setFieldValue('delivery_hour_type', e.target.value);
                    setFieldValue(
                      'delivery_hours',
                      prevDeliveryHours || DEFAULT_DELIVERY_HOURS,
                    );
                    setPrevDeliveryHours(values.delivery_hours);
                  }}
                  options={DeliveryHourOptions.map((item) => ({
                    ...item,
                    disabled: !!isFetching,
                  }))}
                />
                {values.delivery_hour_type === DeliveryHourType.Same ? (
                  <SameDeliveryHoursSelect
                    errors={errors}
                    setErrors={setErrors}
                    data={convertedDeliveryHours(values?.delivery_hours)}
                    onChange={(d: DeliveryHoursSelectItem) => {
                      const newValue = Object.entries(
                        values.delivery_hours,
                      ).reduce(
                        (
                          result,
                          [key, value]: [
                            string,
                            { from: string; to: string }[],
                          ],
                        ) => {
                          const isUpdateHour = d.hours[0].from && d.hours[0].to;
                          const isCurrent = key === d.weekdayValue;
                          const isSelected = value[0].from && value[0].to;
                          if (
                            (isUpdateHour && (isCurrent || isSelected)) ||
                            (!isUpdateHour && isCurrent)
                          ) {
                            return Object.assign(result, { [key]: d.hours });
                          }
                          return Object.assign(result, { [key]: value });
                        },
                        {},
                      );
                      setFieldValue('delivery_hours', newValue);
                    }}
                  />
                ) : (
                  <DeliveryHoursSelect
                    errors={errors}
                    setErrors={setErrors}
                    data={convertedDeliveryHours(values?.delivery_hours)}
                    onChange={(d: DeliveryHoursSelectItem) => {
                      setFieldValue('delivery_hours', {
                        ...values.delivery_hours,
                        ...{ [d.weekdayValue]: d.hours },
                      });
                    }}
                  />
                )}
                <Divider sx={{ my: 2 }} />
                <Typography variant='h5' color={themes.color.black}>
                  Time Slot
                </Typography>
                <Typography mt={0.8}>
                  Set the time slots that customer can select for pickup.
                </Typography>

                <Field.Select
                  fullWidth={false}
                  name='delivery_window'
                  options={GAP_TIME_OPTIONS.map((o) => ({
                    value: o.value,
                    label: `${o.label} each`,
                  }))}
                  formControlProps={{
                    sx: { width: '50%', mt: 1 },
                  }}
                  onChange={() => {
                    resetDeliveryHoursLimit(values.delivery_hours);
                  }}
                />
                <Typography variant='h5' color={themes.color.black} mt={2}>
                  Order Limit (optional)
                </Typography>
                <Typography mt={0.8}>
                  Limit the number of orders customers can place during each
                  time slot.
                </Typography>
                {!isShowOrderLimitPhase2 ? (
                  <Field.TextInput
                    type='number'
                    name='limit_all_slot'
                    inputProps={{
                      min: 1,
                      step: 'any',
                    }}
                    sx={{ width: '50%', mt: 1 }}
                  />
                ) : (
                  <RadioGroup
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      label: {
                        width: 'fit-content',
                      },
                    }}
                    onChange={(e) => {
                      setFieldValue('limit_type', e.target.value);
                      if (e.target.value === OrderLimitType.LimitAllSlot) {
                        setTimeout(
                          () => orderLimitAllInputRef.current.focus(),
                          0,
                        );
                      }
                    }}
                    value={values.limit_type}
                  >
                    <FormControlLabel
                      value={OrderLimitType.LimitAllSlot}
                      control={<Radio />}
                      label='Set for all time slots'
                    />
                    {values.limit_type === OrderLimitType.LimitAllSlot && (
                      <Field.TextInput
                        inputRef={orderLimitAllInputRef}
                        type='number'
                        name='limit_all_slot'
                        inputProps={{
                          min: 1,
                          step: 'any',
                        }}
                        sx={{ width: '50%' }}
                      />
                    )}
                    <FormControlLabel
                      value={OrderLimitType.LimitIndividualSlot}
                      control={<Radio />}
                      label='Set for individual time slots'
                    />
                    {values.limit_type ===
                      OrderLimitType.LimitIndividualSlot && (
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        p={1.2}
                        sx={{
                          mt: 1,
                          background: themes.bg.lightPurple,
                          borderRadius: '5px',
                        }}
                      >
                        <Typography>
                          {hasSetLimitTimeSlots(data.delivery.delivery_hours)
                            ? 'Order limit has been set for individual time slots'
                            : 'Set order limit for individual time slots'}
                        </Typography>
                        {hasSetLimitTimeSlots(data.delivery.delivery_hours) ? (
                          <IconButton
                            onClick={() => setOpenSetLimitDialog(true)}
                          >
                            <Icon name='ionic-ios-settings' useBackgroundImg />
                          </IconButton>
                        ) : (
                          <Button
                            buttonType='default'
                            noRounder
                            onClick={() => setOpenSetLimitDialog(true)}
                          >
                            Set Limit
                          </Button>
                        )}
                      </Stack>
                    )}
                  </RadioGroup>
                )}

                <Divider sx={{ my: 2 }} />
                <Typography variant='h5' color={themes.color.black}>
                  Next Available Time
                </Typography>
                <Typography mt={0.8}>
                  Set the length of time it takes to complete a pickup order,
                  which will determine the next available time a customer can
                  place an order.
                </Typography>

                <Field.Select
                  fullWidth={false}
                  name='next_available_time'
                  options={GAP_TIME_OPTIONS}
                  formControlProps={{
                    sx: { width: '50%', mt: 1 },
                  }}
                  onChange={() => {
                    resetDeliveryHoursLimit(values.delivery_hours);
                  }}
                />

                <Divider sx={{ my: 2 }} />
                <Typography variant='h5' color={themes.color.black}>
                  Special Hours
                </Typography>
                <Typography>
                  Set hours for an occasion (holiday, vacation, construction
                  etc.)
                </Typography>
                <Stack my={1} spacing={1}>
                  {values?.special_hours.length
                    ? values?.special_hours?.map((item, idx) => (
                        <Stack
                          // eslint-disable-next-line react/no-array-index-key
                          key={idx}
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          sx={{
                            borderRadius: '5px',
                            p: 1,
                            px: 2,
                            background: themes.bg.lightPurple,
                          }}
                        >
                          <Typography>
                            {item.name} (
                            {`${moment(item.start_date).format(
                              'MM/DD/YYYY',
                            )} - ${moment(item.end_date).format('MM/DD/YYYY')}`}
                            )
                          </Typography>
                          <ElementSelect
                            paperProps={{
                              sx: {
                                width: 160,
                              },
                            }}
                            elementSelect={() => (
                              <MoreHorizIcon
                                sx={{ color: themes.color.violet900 }}
                              />
                            )}
                            onChange={(o) => {
                              if (o.value === 'edit') {
                                setSelectSpecialHours({ ...item, idx });
                                setOpenSpecialHours(true);
                              }
                              if (o.value === 'delete') {
                                setFieldValue(
                                  'special_hours',
                                  values.special_hours.filter(
                                    (_, i) => idx !== i,
                                  ),
                                );
                              }
                            }}
                            options={[
                              {
                                label: 'Edit',
                                value: 'edit',
                              },
                              {
                                label: 'Delete',
                                value: 'delete',
                              },
                            ]}
                          />
                        </Stack>
                      ))
                    : ''}
                </Stack>

                {openSetLimitDialog && (
                  <DialogIndividualTimeSlots
                    open={openSetLimitDialog}
                    stores={{ id: data.id, ...values }}
                    onClose={() => setOpenSetLimitDialog(false)}
                    onSaveDataSetLimit={(val) =>
                      setFieldValue('delivery_hours', val)
                    }
                  />
                )}

                {openSpecialHours && (
                  <DialogAddSpecialHours
                    open
                    invalidDays={
                      selectSpecialHours
                        ? getInValidDays(values.special_hours).filter(
                            (e) =>
                              !getInValidDays([selectSpecialHours]).includes(e),
                          )
                        : getInValidDays(values.special_hours)
                    }
                    specialHours={selectSpecialHours}
                    onClose={() => {
                      setSelectSpecialHours(null);
                      setOpenSpecialHours(false);
                    }}
                    onSave={(val) => {
                      if (val.idx || val.idx === 0) {
                        return setFieldValue(
                          'special_hours',
                          values.special_hours.map((e, idx) =>
                            val.idx === idx ? val : e,
                          ),
                        );
                      }
                      return setFieldValue('special_hours', [
                        ...values.special_hours,
                        val,
                      ]);
                    }}
                  />
                )}

                <Button
                  noRounder
                  buttonType='default'
                  onClick={() => setOpenSpecialHours(true)}
                >
                  Add Special Hours
                </Button>
              </>
            ) : (
              ''
            )}
            <Button
              disabled={
                !isValid ||
                !dirty ||
                !!errors.filter((err) => !!err.error).length
              }
              className='save-change-btn'
              fullWidth
              loading={isLoading || isFetching}
              type='submit'
            >
              Save Changes
            </Button>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
